import { PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENABLED, PUBLIC_SENTRY_ENVIRONMENT } from "$env/static/public"
import * as Sentry from "@sentry/sveltekit"

Sentry.init({
  enabled: PUBLIC_SENTRY_ENABLED === "true",
  dsn: PUBLIC_SENTRY_DSN,
  environment: PUBLIC_SENTRY_ENVIRONMENT,
  integrations: [Sentry.captureConsoleIntegration()],

  // TODO: Reduce this value in production when user numbers start to increase.
  tracesSampleRate: 1.0,
})

export const handleError = Sentry.handleErrorWithSentry()
