import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~10],
		"/acceptable-use-policy": [12],
		"/account": [13,[2]],
		"/account/billing": [~14,[2]],
		"/account/community-fund": [15,[2]],
		"/account/community-fund/applications/[application_id=uuid]": [16,[2]],
		"/account/community-fund/apply": [17,[2]],
		"/account/fighter": [~18,[2]],
		"/account/fighter/apply": [19,[2]],
		"/account/profile": [20,[2]],
		"/account/security": [21,[2]],
		"/account/subscriptions": [22,[2]],
		"/admin": [23,[3]],
		"/admin/community-fund/applications": [24,[3]],
		"/admin/community-fund/applications/[application_id=uuid]": [25,[3]],
		"/admin/community-fund/create-project": [26,[3]],
		"/admin/fighter-applications": [27,[3]],
		"/admin/fighter-applications/[application_id=uuid]": [28,[3]],
		"/admin/invite-user": [29,[3]],
		"/auth/link-invalid": [30,[4]],
		"/auth/reset-password": [31,[4]],
		"/auth/sign-in": [32,[4]],
		"/auth/sign-up": [33,[4]],
		"/community": [34,[5]],
		"/community/[slug=slug]": [35,[5]],
		"/contact": [36],
		"/events": [37],
		"/events/[slug=slug]": [38],
		"/fighters-home": [40],
		"/fighters": [39],
		"/home": [41,[6]],
		"/messages": [42,[7]],
		"/messages/[chat_id=uuid]": [43,[7]],
		"/news": [44],
		"/news/[slug=slug]": [45],
		"/onboarding": [46],
		"/posts": [47,[8]],
		"/posts/create": [48,[8]],
		"/posts/edit": [49,[8]],
		"/privacy-policy": [50],
		"/search": [~51],
		"/subscription/checkout": [~52,[9]],
		"/subscription/plan": [53,[9]],
		"/support": [54],
		"/support/faq": [55],
		"/support/fighter-guide": [56],
		"/terms": [57],
		"/videos": [~59],
		"/videos/[slug=slug]": [~60],
		"/video/[slug=slug]": [~58],
		"/[username=username]": [11]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';